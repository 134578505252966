import * as React from "react"
import { useParams } from "react-router-dom";
import spanovi from "../data/spanovi.json";

const SpanDetalji = () => {
  const { spanId } = useParams();
  const span = spanovi.find((span) => span.id == spanId);
  const images = span.images || [span.image];
  const [selectedImage, setSelectedImage] = React.useState(span.image);

  console.log(span);

  const getUrl = (url) => {
    const metri = url.includes("200m") ? "200m" : "12m";

    if (url.includes("warehouse")) {
      return "Tennis Warehouse " + metri;
    } else if (url.includes("point")) {
      return "Tennis Point " + metri;
    }
  };



  return (
    <div>
      <div id="spanovi">
        <div className="container">
          <h2>
            {span.name} {span.debljina.join("/")} mm
          </h2>
          <div className="row">
            <div className="col-md-6">
              <img
                className="span-single-img"
                src={"/img/spanovi/" + selectedImage}
                alt="Span"
              />
              {
                images?.length > 0 && (
                  <div style={{
                    display: "flex",
                    height: "60px",
                    padding: "5px",
                    justifyContent: "center",
                  }}>
                    {images.map((image, index) => (
                      <img
                        key={index}
                        src={"/img/spanovi/" + image}
                        alt="Avatar"
                        onClick={() => setSelectedImage(image)}
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "cover", 
                          cursor: "pointer",
                          border: `1px solid ${selectedImage === image ? "black" : "transparent"}`,
                          borderRadius: "5px",
                          margin: "0 5px",
                        }}
                      />
                    ))}
                  </div>
                )
              }
            </div>
            <div className="col-md-6">
              <h3>Opis:</h3>
              <p>{span.description}</p>
              <h3>Cijene:</h3>
              <div className="span-price-row">
                <div>
                  <b>Španovanje</b>
                </div>
                <div className="span-price">
                  20KM
                </div>
              </div>
              <div className="span-price-row">
                <div>
                  <b>Žica 12m</b>
                </div>
                <div className="span-price">{span.price[0]}KM</div>
              </div>

              <h3>Linkovi:</h3>
              {span.url.map((link, index) => (
                <>
                  <a
                    className="link-underline"
                    key={`${link}-${index}`}
                    href={link}
                  >
                    {getUrl(link)}
                  </a>
                  <br />
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpanDetalji;
